.default {
  text-align: center;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #e7e7e7;
  white-space: nowrap;
}

.default.highlight {
  background-color: var(--warning);
}

.default.sent {
  background-color: var(--color-success-300);
  color: var(--color-success-700);
}

.select {
  min-width: 150px;  
}

.filter {
  min-width: 378px;
}
.page-header{
  display: flex;
}


.page-header__title{
  margin: var(--m-16) 0;
}

.tabs{
  margin: var(--m-16) 0;
}

.actions{
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.actions__left{
  width: 350px;
}
.actions__right{
  display: grid;
  grid-gap: var(--p-8);
}

.pageHeader {
    composes: pageHeader from './CampsList.module.css';
  }
  
  .editSection {
    padding-bottom: 2rem;
  }

  .archiveText {
    font-size: 1.5rem;
  }
.modal {
  max-width: 800px;
}

.modal_wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.image_container {
  width: 50%;
}

.image_container img {
  transition: opacity .3s ease-in-out;
}

.faded {
  opacity: 0.2;
}

.input_container {
  width: 50%;
  padding: 0 40px;
}

.preview {
  position: relative;
  width: 100%;
  height: 220px;
  z-index: 1;
  overflow: hidden;
  background-color: var(--color-primary-100);
  margin-top: 40px;
}

.preview img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.input_file {
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.input_file input {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.input_file_btn {
  pointer-events: none;
  transition: filter .2s ease-in-out;
}

.input_file:hover .input_file_btn {
  filter: brightness(0.9);
}

.file_name {
  margin-top: 5px;
  color: var(--color-grey-700);
  font-size: 14px;
  line-height: 1.2;
  word-break: break-word;
}
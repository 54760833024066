.slots-groups{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.slots{
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 320px);
}
.slot{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: var(--p-16);
}

.title-rotation{
  display: flex;
  width: 100%;
  border-bottom: 2px solid var(--c-dusty-grey);
  padding-bottom: var(--p-8);
  margin-bottom: var(--p-16);
}

.groups{
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 250px));
  grid-gap: var(--p-8);
  width: 100%;
}

.button-add-group{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--c-dusty-grey);
  color: var(--c-dusty-grey);
  border-radius: 5px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.1s ease;
}
.button-add-group:hover{
  transform: scale(0.95);
  transition: 0.1s ease;
}

.button-add-group__icon{
  max-width: 40px;
}

.button-add-group__label{
  width: 100%;
  text-align: center;
}

.group_dropzone {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.group_dropzone.active.dropzone_disabled {
  opacity: 0.5;
}

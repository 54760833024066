.menuBar {
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  /* height: var(--header-height); */
  height: 5.5rem;
  background-color: var(--color-white);
  border-bottom: 2px solid var(--color-grey-300);
  padding: .8rem .8rem;
  /* overflow-y: hidden; */
  /* overflow-x: auto; */
}

/* width */
.menuBar::-webkit-scrollbar {
  height: .2rem;
}

/* Track */
.menuBar::-webkit-scrollbar-track {
}

/* Handle */
.menuBar::-webkit-scrollbar-thumb {
  background: var(--color-grey-300);
}

/* Handle on hover */
.menuBar::-webkit-scrollbar-thumb:hover {
  /* background: #0363ca; */
}

.menuList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: .2rem;
  max-width: 100%;
  width: 100%;
}

.menuListSecond {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: .2rem;
  max-width: 100%;
  width: 100%;
}

.menuListItem {
  height: 100%;
  margin: 0 var(--m-8);
}

.menuListItemDropdown {
  color: var(--color-blue-500);
  text-decoration: none;
  background-color: transparent;
}

.userDropdown {
  cursor: pointer;
}


/* Header */
.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    padding-top: 66px;
    padding-left: 100px;
}

.upperCase {
  text-transform: uppercase;
}

.sectionDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionHeader {
}
.sectionHeader a {
}

.sectionSubheader {
  padding-left: 3rem !important;
  color: #8aa4af;
  background-color: #2c3b41 !important;
  display: none;
}
.sectionSubheader.open {
  display: block;
}

.sectionSubheader:hover {
  color: #fff !important;
  background-color: #2c3b41 !important;
}

.sidebarMenuHeader {
  font-size: .9em;
  font-weight: 400;
  cursor: initial !important;
}

.sidebarMenuHeader span {
  font-size: .84em !important;
  cursor: pointer !important;
}

.sidebarMenu {
    z-index: 11;
    overflow-y: auto;
    padding-bottom: 60px;
    height: 100%;
    position: fixed;
    left: 0;

    /* width: 350px; */
    width: 100px;

    margin-top: 66px;
    /* transform: translateX(-278px); */

    transition: width 300ms ease-in-out;
    /* transition: transform 250ms ease-in-out; */

    background: linear-gradient(180deg, #222d32 0%, #222d32 100%);
}
.sidebarMenuInner{
    margin:0;
    padding:0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li{
    list-style: none;
    color: #b8c7ce;
    font-weight: 500;
    padding: 13px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    border-left: 3px solid transparent;
    border-left-width: 3px;
    border-left-style: solid;
    border-right: 3px solid transparent;
    transition: background-color 50ms ease-in;
}

.sidebarMenuInner li:hover {
  background-color: #1e282c;
  color: #fff
}

.sidebarMenuInner.minimized li{
    min-height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.minifiedMenuText {
  padding-top: 0.3rem;
  font-size: .75rem;
  text-transform: none;
  overflow: hidden;
  white-space: nowrap;
}

.listItem.active {
  background-color: #1e282c;
  color: #fff;
  border-left-color: #3c8dbc;
}

.sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
}

.sidebarMenuInner li span:hover {
  text-decoration: underline;
}

.sidebarMenuInner li a{
    color: #b8c7ce;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.openSidebarMenu:checked ~ .sidebarMenu {
    /* transform: translateX(0); */
    width: 350px;
}

.openSidebarMenu {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 25px;
    /* left: 39px; */
    height: 25px;
    width: 25px;
}
.spinner {
    /* transition: all 0.3s; */
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}
.horizontal {
    /* transition: all 0.3s; */
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
.diagonal.part1 {
    position: relative;
    /* transition: all 0.3s; */
    box-sizing: border-box;
    float: left;
}
.diagonal.part2 {
    /* transition: all 0.3s; */
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
.openSidebarMenu:checked ~ .sidebarIconToggle > .horizontal {
    /* transition: all 0.3s; */
    box-sizing: border-box;
    opacity: 0;
}
.openSidebarMenu:checked ~ .sidebarIconToggle > .diagonal.part1 {
    /* transition: all 0.3s; */
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}
.openSidebarMenu:checked ~ .sidebarIconToggle > .diagonal.part2 {
    /* transition: all 0.3s; */
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}
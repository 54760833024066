.switch {
  position: relative;
  display: inline-block;
  width: var(--w-40);
  height: var(--h-20);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderRound {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-grey-500);
  transition: 0.3s ease-in-out;
  transition-property: transform, background-color;
  border-radius: var(--rad-24);
}

.sliderRound::before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.3s ease-in-out;
  transition-property: transform, background-color;
  border-radius: 50%;
}

input:checked + .sliderRound {
  background-color: var(--color-primary-500);
}

input:focus + .sliderRound {
  box-shadow: 0 0 1px var(--color-primary-500);
}

input:checked + .sliderRound::before {
  -webkit-transform: translateX(1.2rem);
  -ms-transform: translateX(1.2rem);
  transform: translateX(1.2rem);
}

.label{
  margin-left: var(--m-8);
}
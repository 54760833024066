.pageHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-32) 0;
  }

.creditSpacing {
  padding-right: 7px;
}

.editCommentButton {
  font-size: .9em;
}

.inputComment {
  min-width: 20rem;
}
.wrapper{
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.tab{
  cursor: pointer;
  white-space: nowrap;
}

.active {
  background-color: var(--c-primary);
  color: #ffffff;
}

.active:hover{
  background-color: var(--c-primary-light);
  color: #ffffff;
}
.page-header {
  display: flex;
}

.page-header__title {
  margin: var(--m-16) 0;
}

.top-control {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}

.tabs {
  margin: var(--m-16) 0;
}

.weekselector {
  width: 50%;
  max-width: 500px;
}

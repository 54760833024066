.pageHeader {
  composes: pageHeader from './AccommodationsList.module.css';
}
  
.editSection {
  padding-bottom: 2rem;
}

.archiveText {
  font-size: 1.5rem;
}
@value primary, dusty-grey, malachite, pomegranate, black from "../../../constants/colors.module.css";
@value boun-s, boun-2xs, min-boun-xs from "../../../constants/boundaries.module.css";

.group {
  width: 100%;
  display: inline-block;
  position: relative;
}

.label {
  position: absolute;
  left: boun-2xs;
  top: 0;
  transition: all 0.3s ease-in-out;
  font-size: 1.25rem;
  color: dusty-grey;
  cursor: text;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.input {
  appearance: none;
  width: 100%;
  border: none;
  border-bottom: 0.0625rem solid black;
  padding-bottom: 0.25rem;
  font-size: 1.25rem;
  color: black;
  background-color: transparent;
  box-shadow: none;
  font-family: system-ui;
}

.hasError .label {
  color: pomegranate;
}

.input:focus {
  outline: none;
}

.input:valid {
  border-color: malachite;
}

.active .label,
.input:focus ~ .label {
  top: min-boun-xs;
  left: 0;
  font-size: 0.75rem;
}

.bar {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 0.125rem solid primary;
}

.hasError .input,
.hasError .bar {
  border-color: pomegranate;
}

.input:focus ~ .bar {
  animation: displayBar 0.3s ease-out forwards;
}

.picto {
  position: absolute;
  right: 0;
  height: 1.375rem;
  width: 1.375rem;
  color: dusty-grey;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.picto:hover {
  color: black;
}

.picto:active {
  color: dusty-grey;
}

@keyframes displayBar {
  from { width: 0; }
  to { width: 100%; }
}

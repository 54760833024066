.recap {
    display: flex;
    flex-direction: column
}

.label {
    font-size: .8rem;
}

.value {
    font-size: 1.4rem;
    font-weight: 600;
}

.subLabel {
    font-size: .7rem;
}
.wrapper {
  padding: 30px 0;
}

.week_selector {
  width: 100%;
  max-width: 350px;
}

.extra_type {
  width: 100%;
  max-width: 250px;
}

.extra_flag {
  min-width: 140px;
  padding: 5px 15px;
  background-color: var(--c-royal-blue);
  color: var(--c-white);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid var(--c-black);
  box-shadow: 2px 2px 1px rgba(0,0,0,0.7);
}

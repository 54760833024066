.menu {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem;
    border-bottom: 2px solid rgba(34,36,38,.15);
}

.header {
    margin-bottom: 1.6rem;
}

.menuItem {
    width: 50%;
}

.navText {
    text-align: center;
    font-size: 1.3rem;
}

.link {
    padding: .85714286em 1.14285714em !important;
    color: rgba(0,0,0,.95);
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-radius: 0;
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin: 0 0 -2px;
    border-bottom-width: 2px;
    -webkit-transition: color .1s ease;
    transition: color .1s ease;
}

.active {
    background-color: transparent;
    box-shadow: none;
    border-color: #1b1c1d;
    font-weight: 700;
    color: rgba(0,0,0,.95);
}

.link:hover {
    color: rgba(0,0,0,.95);
}
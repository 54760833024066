.pageHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-32) 0;
}

.editSection {
  padding-bottom: 2rem;
}

.c-pointer {
  cursor: pointer;
}

.card {
  background-color: var(--color-white);
  border-radius: var(--rad-4);
  padding: var(--p-32);
}

.loginCard {
  max-width: 30rem;
  text-align: center;
  margin: 10rem auto 0 auto;
}
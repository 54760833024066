.filter_wrapper {
  margin-bottom: 30px;
}

.yearSelect {
  max-width: 200px;
  margin-bottom: 30px;
}

.filters {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
}

.field {
  width: calc(100% / 3 - 20px);
  min-width: 300px;
  margin-right: 20px;
  margin-bottom: 10px;
}

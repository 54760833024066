@value black from "../../../constants/colors.module.css";
@value boun-xl, boun-m, rad-s from "../../../constants/boundaries.module.css";

.overlay {
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  animation: backgroundFade 0.3s ease-in-out forwards;
  height: 100vh;
}

.modal {
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 50%;
  background-color: white;
  border-radius: rad-s;
  padding: boun-xl boun-m;
  opacity: 0;
  animation: modalAppear 0.5s ease-in-out forwards 0.3s;
}

.modal.s {
  min-width: 22rem;
}

.modal.m {
  min-width: 40rem;
}

.modal.l {
  min-width: 58rem;
}

.header {
  width: 100%;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.close {
  position: absolute;
  right: boun-m;
  top: boun-m;
  background-color: transparent;
  border: none;
  width: 2rem;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .modal.s,
  .modal.m,
  .modal.l {
    min-width: calc(100vw - 100px);
    left: calc(50% + 50px);
    min-height: 100vh;
    box-sizing: border-box;
  }
}

@keyframes backgroundFade {
  from { opacity: 0; }
  to { opacity: 0.4; }
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: translate(-50%, -30%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@value error-red from "../../../../constants/colors.module.css";

.pageHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-32) 0;
}

.articleIdCol {
  cursor: pointer;
}

.articleIdTooltipContent {
  text-align: center;
}

.list {
  padding-bottom: 1.7rem;
}

.submitErrorLabel {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: error-red;
  padding-top: .5rem;
}

.topMenu {
  display: flex;
  justify-content: space-between;
}
:root {
  --color-primary-100: #ebedf3;
  --color-primary-300: #b1b6d1;
  --color-primary-500: #3b488b;
  --color-primary-700: #232b53;
  --color-secondary-300: #c8cce3;
  --color-secondary-500: #7680ba;
  --color-secondary-700: #474d70;
  --color-accent-500: #9648e3;
  --color-success-300: #c4eac2;
  --color-success-500: #6cca67;
  --color-success-700: #41793e;
  --color-error-300: #eeafb8;
  --color-error-500: #d4364d;
  --color-error-700: #7f202e;
  --color-warning-300: #f6c3aa;
  --color-warning-500: #e8682b;
  --color-warning-700: #8b3e1a;
  --color-white: #fff;
  --color-black: #292a2d;
  --color-grey-100: #f9fafc;
  --color-grey-300: #cfd1d5;
  --color-grey-500: #888c97;
  --color-grey-700: #52545b;
  --color-blue-500: #007bff;
}

.select {
  min-width: 200px;
}

.field {
  max-width: 350px;
  width: 100%;
}

.empty {
  color: var(--color-grey-700);
}

.header {
  display: grid;
  grid-template-areas: "headerLeft headerCenter headerRight";
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  background-color: #222d32;
  z-index: 10;
  color: #fff;
}

.headerLeft {
  grid-area: headerLeft;
  align-self: center;
  grid-column-start: 1;
  grid-column-end: 2;
}

.headerCenter {
  grid-area: headerCenter;
  justify-self: center;
  align-self: center;
  grid-column-start: 2;
  grid-column-end: 3;
  font-size: 1.3em;
  font-weight: 500;
}

.headerRight {
  grid-area: headerRight;
  justify-self: end;
  align-self: center;
  grid-column-start: 3;
  grid-column-end: 4;
  margin-right: 40px;
  min-width: 50%;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}
.player {
  position: relative;
  user-select: none;
  cursor: grab;
}

.player_inner {
  padding: var(--p-8) var(--p-16);
}

.full_day_tournament {
  composes: full_day_tournament from "../../colors.module.css";
}
.full_day {
  composes: full_day from "../../colors.module.css";
}
.full_day_children {
  composes: full_day_children from "../../colors.module.css";
}
.full_day_test {
  composes: full_day_test from "../../colors.module.css";
}
.tennis_and_golf {
  composes: tennis_and_golf from "../../colors.module.css";
}
.multisport {
  composes: multisport from "../../colors.module.css";
}
.weekend {
  composes: weekend from "../../colors.module.css";
}
.weekend_intensif {
  composes: weekend_intensif from "../../colors.module.css";
}
.baby_tennis {
  composes: baby_tennis from "../../colors.module.css";
}
.padel {
  composes: padel from "../../colors.module.css";
}
.half_day_padel {
  composes: half_day_padel from "../../colors.module.css";
}
.half_day_weekend {
  composes: half_day_weekend from "../../colors.module.css";
  border: 3px solid #ff0000;
}
.padel_and_tennis {
  composes: padel_and_tennis from "../../colors.module.css";
}
.half_day {
  composes: half_day from "../../colors.module.css";
}
.half_day_afternoon {
  composes: half_day_afternoon from "../../colors.module.css";
}
.half_day_children {
  composes: half_day_children from "../../colors.module.css";
}
.full_day_language {
  composes: full_day_language from "../../colors.module.css";
}
.half_day_language {
  composes: half_day_language from "../../colors.module.css";
  border: 3px solid #93c47d;
}
.night_session_adults_18 {
  composes: night_session_adults_18 from "../../colors.module.css";
}
.night_session_adults_20 {
  composes: night_session_adults_20 from "../../colors.module.css";
}
.night_session_children_18 {
  composes: night_session_children_18 from "../../colors.module.css";
}
.night_session_children_20 {
  composes: night_session_children_20 from "../../colors.module.css";
}

.player-selected {
  border: 5px solid var(--c-white);
}

.number-players-selected {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  top: -10px;
  right: -10px;
  height: 30px;
  width: 30px;
  background-color: var(--c-primary-light);
  color: var(--c-white);
}

.player_label {
  margin: 0 6px;
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.tooltipPlayer p {
  text-align: left;
  margin-bottom: 3px;
}

.context_menu {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  min-width: 200px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  color: #000;
  cursor: default;

  display: none; /* closed */
}

.context_menu.opened {
  display: block;
}

.context_menu button {
  display: block;
  background-color: transparent;
  width: 100%;
  padding: 5px 0;
  box-shadow: none;
  border: none;
  color: #000;
  text-align: left;
  font-size: 14px;
  outline: none;
}

.context_menu .context_title {
  margin: 0;
  padding: 0 0 15px;
  font-weight: 500;
}

.context_menu button:hover {
  color: #007bff;
}

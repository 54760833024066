.tooltipContent {
  text-align: center;
}

.col {
  display: inline-block;
  outline: none;
}

.clickable {
  cursor: pointer;
}
.pageHeader {
  composes: pageHeader from '../ClientsList.module.css';
}

.formSpacing {
  margin-bottom: var(--m-32);
}

.formWidth {
  max-width: 60%;
  margin: 0 auto;
}

.fieldError {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.archiveText {
  font-size: 1.5rem;
}
.medias_input {
  position: relative;
  border: 1px solid var(--color-grey-300);
  padding: 10px 15px 20px;
  margin: 20px 0;
}

.medias_input.no_border {
  border: none;
  padding: 0;
  margin: 0;
}

.medias_row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.medias_row .item {
  position: relative;
  z-index: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 200px;
  height: 120px;
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--color-primary-100);
}

.medias_row .item:hover .remove_btn {
  opacity: 1;
}

.medias_row .item .remove_btn {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  padding: 0;
  background-color: var(--color-primary-700);
  border: 1px solid var(--color-white);
  border-radius: 50%;
  color: var(--color-white);
  line-height: 1;
  cursor: pointer;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.medias_row .item .remove_btn svg {
  width: 10px;
}

.medias_row .item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.min_required {
  margin-bottom: 20px;
  color: var(--color-grey-500);
  font-size: 14px;
  font-weight: 600;
}

.required {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0.01;
  pointer-events: none;
  cursor: default;
}

/* MODAL */

.modal {
  max-width: 1200px;
}

.modal_body {
  max-height: calc(100vh - 200px);
  padding: 0 30px 30px;
  overflow-y: auto;
  flex: 1 1 auto;
}

.search {
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
  background-color: var(--color-white);
}

.search_input {
  flex: 1 1 auto;
  max-width: 300px;
  margin-right: 20px;
}

.medias_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

@media (max-width: 1200px) {
  .medias_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1024px) {
  .medias_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .medias_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.media_item {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
}

.media_item_img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--color-primary-100);
  border: 0px solid var(--color-blue-500);
  cursor: pointer;
  transition: border-width .2s ease-in-out;
}

.media_item_img.selected {
  border-width: 4px;
}

.media_item_img img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: transform .2s ease-in-out;
  transform: scale(1);
}

.media_item_img:hover img {
  transform: scale(1.02);
}

.reset_btn {
  margin-right: auto;
  padding: 10px;
  background-color: transparent;
  border: none;
  color: var(--color-error-500);
  font-weight: 600;
  outline: none;
  transition: filter .2s ease-in-out;
}

.reset_btn:focus {
  outline: none;
}

.reset_btn:hover {
  filter: brightness(0.8);
}
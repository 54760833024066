:root {
  /* Margin & paddings */
  --m-4: 0.25rem;
  --m-8: 0.5rem;
  --m-16: 1rem;
  --m-32: 2rem;
  --m-64: 4rem;
  --m-128: 8rem;

  --p-4: 0.25rem;
  --p-8: 0.5rem;
  --p-16: 1rem;
  --p-32: 2rem;
  --p-64: 4rem;
  --p-128: 8rem;

  /* Width */
  --w-8: 0.5rem;
  --w-16: 1rem;
  --w-24: 1.5rem;
  --w-30: 1.875rem;
  --w-36: 2.25rem;
  --w-40: 2.5rem;

  /* Height */
  --h-12: 0.75rem;
  --h-16: 1rem;
  --h-18: 1.125rem;
  --h-20: 1.25rem;
  --h-24: 1.5rem;
  --h-28: 1.75rem;
  --h-30: 1.875rem;
  --h-50: 3.125rem;
  --h-58: 3.625rem;
  --h-80: 5rem;

  /* Radiuses */
  --rad-2: 0.125rem;
  --rad-4: 0.25rem;
  --rad-8: 0.5rem;
  --rad-16: 1rem;
  --rad-24: 1.5rem;
  --rad-full: 50%;

  /* Elevation */
  --z-1: 1;
  --z-2: 2;
  --z-3: 3;
  --z-4: 4;
  --z-5: 5;

  /* Brakepoints */
  --bp-xs: 0;
  --bp-s: 0;
  --bp-m: 1023px;
  --bp-l: 0;

  /* Misc. widths or heights */
  --header-height: 3rem;
  --picturePreview-height: 6rem;
  --picturePreview-width: 8rem;
}

.headCell {
  vertical-align: middle;
  white-space: nowrap;
}

.headCell.headCellSorted {
  color: var(--primary);
}

.headCellSortedDesc::after {
  vertical-align: center;
  margin-left: var(--m-8);
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml,<svg class='bi bi-chevron-down' width='1em' height='1em' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M3.646 6.646a.5.5 0 01.708 0L10 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z' clip-rule='evenodd'></path></svg>");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
}

.headCellSortedAsc::after {
  vertical-align: center;
  margin-left: var(--m-8);
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml,<svg class='bi bi-chevron-up' width='1em' height='1em' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M9.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L10 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z' clip-rule='evenodd'></path></svg>");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
}

.paginationWrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
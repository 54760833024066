.full_day_tournament {
  background-color: #ffc000;
  color: #000000;
}
.full_day {
  background-color: #4a86e8;
  color: #000000;
}
.full_day_children {
  background-color: #4a86e8;
  color: #000000;
}
.full_day_test {
  background-color: #4a86e8;
  color: #ffff00;
}
.tennis_and_golf {
  background-color: #ffff00;
  color: #000000;
}
.multisport {
  background-color: #fce5cd;
  color: #6aa84f;
}
.weekend {
  background-color: #00ffff;
  color: #000000;
}
.weekend_intensif {
  background-color: #6febed;
  color: #000000;
}
.baby_tennis {
  background-color: #d9d2e9;
  color: #000000;
}
.padel {
  background-color: #e69138;
  color: #000000;
}
.half_day_padel {
  background-color: #ff0000;
  color: #00ff00;
}
.padel_and_tennis {
  background-color: #b45f06;
  color: #000000;
}
.half_day {
  background-color: #ff0000;
  color: #ffffff;
}
.half_day_children {
  background-color: #ff0000;
  color: #ffffff;
}
.half_day_afternoon {
  background-color: #e06666;
  color: #000000;
}
.full_day_language {
  background-color: #93c47d;
  color: #000000;
}
.half_day_language {
  background-color: #ff0000;
  color: #00ff00;
}

.half_day_weekend {
  background-color: #6febed;
  color: #ff0000;
}

.night_session_adults_18,
.night_session_adults_20,
.night_session_children_18,
.night_session_children_20 {
  background-color: #000000;
  color: #ffffff;
}
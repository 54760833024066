.pageHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--p-32) 0;
  }

  .editSection {
    padding-bottom: 2rem;
  }

  .successMessage {
    display: inline-block;
    margin-left: 2rem;
    flex: 1;
  }

  .submitBlock {
    display: flex;
    flex-direction: row;
  }

  .submitButton {
    height: 3.1rem;
  }
.select_filter {
    position: relative;
    z-index: 2;
    max-width: 500px;
    margin-bottom: 20px;
}

.nav_tabs {
    border-bottom: none;
}

.table {
    position: relative;
    z-index: 1;
    border-top: 1px solid #dee2e6;
}

.theadFixed {
    position: relative;
}

.theadFixed thead > tr {
    background-color: var(--color-grey-100);
}

.theadFixed th {
    position: sticky;
    background-color: var(--color-grey-100);
    z-index: 10 !important; /* important for fixed TH */
    top: 0px;
    border-top: none;
    line-height: 1.2;
}

.weekHeaderCol {
    position: sticky !important;
    background-color: var(--color-grey-100) !important;
    z-index: 9 !important; /* important for fixed TH */
    left: 0 !important;
}

.weekHeaderColElement {
    font-weight: bold !important;
}

.toggle_week {
    border: none;
    background-color: transparent;
    padding: 0;
    padding-left: 2.25rem;
    outline: none !important;
}

.toggle_week:active, .toggle_week:hover {
    outline: none;
}
.editView {
    
}

.extraColumn {
    text-align: center;
}

.extraInput {
    width: 50%;
    float: right;
    text-align: right;
    font-weight: bold;
}

.archiveText {
    font-size: 1.5rem;
}

.invalid {
    display: inherit;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}
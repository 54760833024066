.column {
    padding-left: 10px !important;
}

.delete {
    border-left: 1px solid rgba(0,0,0,.125);
    padding-left: 1.3rem;
}

.confirmLabel {
    margin-right: 1.3rem;
}
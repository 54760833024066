.reserve {
  position: sticky;
  top: 76px;
  left: 0;
  width: 300px;
  height: calc(100vh - 110px);
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  background: var(--c-athens-gray);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  transition: 0.1s ease;
}
.reserve.isDragIn {
  background: var(--c-silver);
  transition: 0.1s ease;
}

.title {
  padding: var(--p-8);
  width: 100%;
  margin-bottom: var(--p-16);
  text-align: center;
  border-bottom: 1px solid var(--c-dusty-grey);
}

.player {
  width: 100%;
  padding: var(--p-8) var(--p-16);
  user-select: none;
  cursor: grab;
}

.player:active {
  cursor: grabbing;
}

.full_day_tournament {
  composes: full_day_tournament from "../../colors.module.css";
}
.full_day {
  composes: full_day from "../../colors.module.css";
}
.full_day_children {
  composes: full_day_children from "../../colors.module.css";
}
.full_day_test {
  composes: full_day_test from "../../colors.module.css";
}
.tennis_and_golf {
  composes: tennis_and_golf from "../../colors.module.css";
}
.multisport {
  composes: multisport from "../../colors.module.css";
}
.weekend {
  composes: weekend from "../../colors.module.css";
}
.weekend_intensif {
  composes: weekend_intensif from "../../colors.module.css";
}
.baby_tennis {
  composes: baby_tennis from "../../colors.module.css";
}
.padel {
  composes: padel from "../../colors.module.css";
}
.half_day_padel {
  composes: half_day_padel from "../../colors.module.css";
}
.half_day_weekend {
  composes: half_day_weekend from "../../colors.module.css";
  border: 3px solid #ff0000;
}
.padel_and_tennis {
  composes: padel_and_tennis from "../../colors.module.css";
}
.half_day {
  composes: half_day from "../../colors.module.css";
}
.half_day_afternoon {
  composes: half_day_afternoon from "../../colors.module.css";
}
.half_day_children {
  composes: half_day_children from "../../colors.module.css";
}
.full_day_language {
  composes: full_day_language from "../../colors.module.css";
}
.half_day_language {
  composes: half_day_language from "../../colors.module.css";
  border: 3px solid #93c47d;
}
.night_session_adults_18 {
  composes: night_session_adults_18 from "../../colors.module.css";
}
.night_session_adults_20 {
  composes: night_session_adults_20 from "../../colors.module.css";
}
.night_session_children_18 {
  composes: night_session_children_18 from "../../colors.module.css";
}
.night_session_children_20 {
  composes: night_session_children_20 from "../../colors.module.css";
}

.player-selected {
  border: 5px solid var(--c-white);
}

.number-players-selected {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  top: -10px;
  right: -10px;
  height: 30px;
  width: 30px;
  background-color: var(--c-primary-light);
  color: var(--c-white);
}

@value malachite, malachite-light, malachite-lightest, primary, primary-light, primary-lightest, black, white, dusty-grey from "../../../constants/colors.module.css";
@value boun-l, boun-xs, boun-3xs, rad-s from "../../../constants/boundaries.module.css";

.button {
  color: white;
  border-radius: rad-s;
  padding: boun-xs boun-l;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-weight: 700;
}

.button.small{
  padding: boun-3xs boun-xs;
  text-transform: none;
  font-weight: 500;
}

.fill {
  text-transform: uppercase;
  border: none;
}

.fill:disabled,
.fill-disabled {
  background-color: dusty-grey !important;
  color: black;
  cursor: default;
}

.border {
  border: 2px solid primary;
}

.border:disabled,
.border-disabled {
  background-color: transparent;
  border: 2px solid dusty-grey !important;
  color: dusty-grey !important;
}

.hasLoader {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.loader {
  height: 1.5rem;
  margin-right: boun-xs;
}

.border.primary {
  color: primary;
  background-color: primary-lightest;
  border-color: primary;
}

.fill.primary,
.fill:active.primary {
  background-color: primary;
}

.fill:hover.primary,
.border:active.primary {
  background-color: primary-light;
}

.border.green {
  color: malachite;
  background-color: malachite-lightest;
  border-color: malachite;
}

.fill.green,
.fill:active.green {
  background-color: malachite;
}

.fill:hover.green,
.border:active.green {
  background-color: malachite-light;
}

.history {
    margin-top: 3rem;
}

.infoSection {
    margin-bottom: 3.2rem;
}

.addPointsSection {
    background-color: rgba(0,0,0,.05);
    padding: 1rem .7rem;
}

.addPointsForm {
    display: flex;
    justify-content: center;
}

.consumablePrivilegeSection {
    margin-top: 2rem;
}

.privilegesSection {
    margin-bottom: 4rem;
}

.privilegePermanent {
    margin-bottom: 2.5rem;
}

.privilegePermanentName {
    margin-bottom: .3rem;
}

.privilegeConsumableName {
    margin-bottom: 1.4rem;
}

.privilegeSubHeader {
    font-size: .85rem;
}

.privilegeConsumableName {
    font-weight: 700;
}

.privilegeCategoryTitle {
    margin-bottom: .5rem;
    margin-top: 1.3rem;
}

.consumablePrivileges {
    border-left: 1px solid #ccc;
}

.privilegeSelect {
    margin-bottom: 0 !important;
}

.privilegeSubLabel {
    font-size: .7rem;
}

.level {
    margin-top: 2.4rem;
}

.privilegeName {
    font-weight: 600;
}

.confirmLabel {
    margin-right: 1.3rem;
}
.stockCell {
    width: 12rem;
}

.theadFixed {
    position: relative;
}

.theadFixed thead > tr {
    background-color: var(--color-grey-100);
}

.theadFixed th {
    position: sticky;
    background-color: var(--color-grey-100);
    z-index: 10 !important; /* important for fixed TH */
    top: 0px;
    border-top: none;
}

.weekHeaderCol {
    position: sticky !important;
    background-color: var(--color-grey-100) !important;
    z-index: 9 !important; /* important for fixed TH */
    left: 0 !important;
}

.weekHeaderColElement {
    font-weight: bold !important;
}
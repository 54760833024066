.pageHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-32) 0;
}

.exportTable td {
  vertical-align: top;
  padding: 0 8px 10px;
}

.exportTable td:first-child {
  max-width: 300px;
  min-width: 300px;
  line-height: 1.4;
  padding-right: 30px;
}

.exportTable th {
  padding-left: 10px;
  padding-bottom: 20px;
}
.section {
  padding: 20px 0 30px;
}

.section_title {
  display: block;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--c-black);
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.groups {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0 0;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid var(--color-grey-300);
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0,0,0,0.08);
  background-color: var(--c-white);
  margin: 0 20px 20px 0;
  width: calc(100% / 4 - 20px);
  min-width: 200px;
  transition: box-shadow .2s ease-in-out;
}

@media all and (max-width: 1200px) {
  .group {
    width: calc(100% / 3 - 20px);
  }
}

@media all and (min-width: 1620px) {
  .group {
    max-width: 280px;
  }
}

.group:hover {
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.group_inner {
  padding: 8px;
}

.input:disabled {
  background-color: transparent;
  opacity: 0.7;
}

.level {
  padding: 7px 8px 4px;
  font-size: 14px;
}

.player {
  margin: auto 0 0;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: 0 0 5px 5px;
}
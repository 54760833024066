.extraColumn {
    text-align: center;
}

.extraInput {
    width: 50%;
    float: right;
    text-align: right;
    font-weight: bold;
}

.archiveText {
    font-size: 1.5rem;
}

.tags {
    margin-bottom: 30px;
}

.tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--c-royal-blue-lightest);
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 500;
}

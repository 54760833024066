.pageHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--p-32) 0;
  }

.medias_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

@media (max-width: 1620px) {
  .medias_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1200px) {
  .medias_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1024px) {
  .medias_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .medias_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.filters {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filter {
  flex: 1 1 auto;
  max-width: 370px;
  margin-bottom: 30px;
}

.media_item {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
}

.media_item_img {
  position: relative;
  z-index: 1;
  display: block;
  height: 200px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--color-primary-100);
}

.media_item_img.with_hover::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.media_item_img.with_hover:hover::after {
  opacity: 1;
}

.media_item_img.with_hover:hover .media_edit {
  opacity: 1;
}

.media_item_img img {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media_label {
  margin-top: 10px;
  color: var(--color-grey-700);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  word-break: break-all;
}

.media_edit {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-size: var(--fs-16);
  font-weight: 600;
  cursor: pointer;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.media_edit svg {
  width: 20px;
  margin-right: 10px;
}

.media_details_img {
  height: 100%;
  min-height: 368px;
}

.media_details_img img {
  object-fit: contain;
}

.floating_cta {
  position: sticky;
  z-index: 2;
  bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 50px;
}

.back_to_top {
  margin-right: 10px;
  padding: 10px 15px;
  background-color: var(--color-white);
  border-radius: 7px;
  border: none;
  font-size: var(--fs-16);
  font-weight: 500;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.input_file {
  position: relative;
  margin: 0;
  cursor: pointer;
}

.input_file input {
  position: absolute;
  top: 0;
  right: 0;
  height: 1px;
  width: 1px;
  opacity: 0.01;
}
.pageHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-32) 0;
}

.warnMessage {
  padding-bottom: .7rem;
  color: #888;
    font-style: italic;
}
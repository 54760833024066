.player-table {
  margin-top: var(--p-16);
}

.test_acad {
  font-size: 12px;
}

.tennis_data {
  white-space: nowrap;
}

.tennis_data p {
  margin: 0;
  font-size: 14px;
}

.tag {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.section_name {
  white-space: nowrap;
  font-weight: 500;
}

.full_day_tournament {
  composes: full_day_tournament from "../../colors.module.css";
}
.full_day {
  composes: full_day from "../../colors.module.css";
}
.full_day_children {
  composes: full_day_children from "../../colors.module.css";
}
.full_day_test {
  composes: full_day_test from "../../colors.module.css";
}
.tennis_and_golf {
  composes: tennis_and_golf from "../../colors.module.css";
}
.multisport {
  composes: multisport from "../../colors.module.css";
}
.weekend {
  composes: weekend from "../../colors.module.css";
}
.weekend_intensif {
  composes: weekend_intensif from "../../colors.module.css";
}
.baby_tennis {
  composes: baby_tennis from "../../colors.module.css";
}
.padel {
  composes: padel from "../../colors.module.css";
}
.half_day_padel {
  composes: half_day_padel from "../../colors.module.css";
}
.half_day_weekend {
  composes: half_day_weekend from "../../colors.module.css";
  border: 3px solid #ff0000;
}
.padel_and_tennis {
  composes: padel_and_tennis from "../../colors.module.css";
}
.half_day {
  composes: half_day from "../../colors.module.css";
}
.half_day_afternoon {
  composes: half_day_afternoon from "../../colors.module.css";
}
.half_day_children {
  composes: half_day_children from "../../colors.module.css";
}
.full_day_language {
  composes: full_day_language from "../../colors.module.css";
}
.half_day_language {
  composes: half_day_language from "../../colors.module.css";
  border: 3px solid #93c47d;
}
.night_session_adults_18 {
  composes: night_session_adults_18 from "../../colors.module.css";
}
.night_session_adults_20 {
  composes: night_session_adults_20 from "../../colors.module.css";
}
.night_session_children_18 {
  composes: night_session_children_18 from "../../colors.module.css";
}
.night_session_children_20 {
  composes: night_session_children_20 from "../../colors.module.css";
}
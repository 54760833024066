.group {
  border: 2px solid var(--c-dusty-grey);
  border-radius: 5px;
  transition: 0.1s ease;
}

.group.isDragIn {
  transition: 0.1s ease;
  background: var(--c-silver);
}

.group-index {
  padding: var(--p-4) var(--p-8);
  text-align: center;
}

.players-group {
  display: grid;
  padding: var(--p-8);
  margin: var(--m-4);
  border-radius: var(--p-4);
  background: var(--c-athens-gray);
  min-height: 100px;
}
.players-group__title {
  font-size: var(--h-12);
  line-height: var(--h-12);
  padding-bottom: var(--p-4);
  border-bottom: 1px solid var(--c-dusty-grey);
  height: min-content;
}

.pageHeader {
    composes: pageHeader from './RolesList.module.css';
}
  
.editSection {
    padding-bottom: 2rem;
}

.nameColumn {
    width: 75%;
}

.statusColumn {
    width: 25%;
}
.details p {
  margin: 5px 0;
}

.select {
  width: 250px;
}

.table {
  border-collapse: collapse;
}

.label_cell {
  min-width: 150px;
  padding: 8px 15px;
  background-color: #ebebeb;
  border-left: 2px solid var(--color-grey-500);
}

.label_cell label {
  margin: 0;
}

.row {
  margin-bottom: 5px;
}

.row.desc {
  margin-bottom: 20px;
}

.row input, .row textarea, .full_textarea textarea {
  min-width: 400px;
  height: 100%;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  border: 1px solid var(--color-grey-300);
}

.row textarea, .full_textarea textarea {
  min-height: 66px;
}

.full_textarea textarea {
  min-height: 200px;
}

.full_textarea label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.15rem;
}

.full_textarea label::after {
  content: '';
  flex: 1 1 auto;
  height: 1px;
  background-color: var(--color-grey-300);
  margin-left: 20px;
}

.full_textarea textarea {
  width: 100%;
}

.flash {
  max-width: 550px;
}

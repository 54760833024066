.pageHeader {
  composes: pageHeader from './AdminsList.module.css';
}

.fieldError {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.archiveText {
  font-size: 1.5rem;
}
.select_filter {
    position: relative;
    z-index: 2;
    max-width: 500px;
    margin-bottom: 20px;
}

.nav_tabs {
    border-bottom: none;
}

.table_wrapper {
    position: relative;
    z-index: 1;
    max-height: 500px;
    border-top: 1px solid #dee2e6;
}

.table_wrapper tbody td {
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
}

.theadFixed {
    position: relative;
}

.theadFixed th {
    background-color: #e5fefa;
    position: sticky;
    z-index: 2;
    top: 0;
    padding: 15px 10px;
    border-top: none;
    line-height: 1.2;
    font-size: 15px;
    white-space: nowrap;
    height: 50px;
    vertical-align: middle;
}

tr.theadSub th {
    background-color: #e0ecfa;
    text-align: right;
    font-size: 14px;
    padding: 10px 10px 10px 15px;
    top: 48px;
    vertical-align: middle;
}

.weekHeaderCol {
    position: sticky;
    z-index: 1;
    left: 0;
    background-color: var(--color-grey-100);
}

.input_wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.input {
    width: 0;
    min-width: 80px;
    padding: 0 8px;
    height: 28px;
    color: var(--color-black);
    background-color: var(--color-white);
    border: 1px solid #ced4da;
    transition: width .3s ease-in-out;
}

.input_value {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    padding: 0 8px;
    border: 1px solid transparent;
    visibility: hidden;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}

.header {
    margin-bottom: 1.6rem;
}

.section {
    margin-bottom: 3.5rem;
}

.textHeader {
    font-size: 1.25rem;
}
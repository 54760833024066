.page-header{
  display: flex;
}

.page-header__title{
  margin: var(--m-16) 0;
}

.filters-section{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--m-32);
}

.buttons-control{
  display: grid;
}

.buttons-control__affectation{
  margin-bottom: var(--m-8);
}

.weekselector-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.weekselector{
  width: 50%;
  max-width: 500px;
}